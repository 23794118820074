// @ts-check

/* eslint-disable import/no-extraneous-dependencies */
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Merge tailwind classes, removing any conflicting styles.
 *
 * @param {import ('clsx').ClassValue[]} inputs - The classes to merge.
 * @returns string
 */

export default function cn(...inputs) {
    return twMerge(clsx(inputs));
}
